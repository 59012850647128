import React, { FC } from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import e2eImage from '../../../assets/images/img4.jpg';
import Link from '@mui/material/Link';
import { HashLink } from 'react-router-hash-link';
const SectionTextRight1=(props: any) => {
    const { data } = props;
 //console.log(data[0].title,"data");
 function decodeEntity(inputStr:any) {
  var textarea = document.createElement("textarea");
  //console.log(textarea,"textarea")
  textarea.innerHTML = inputStr;
  return textarea.value;
}
const field_description =  decodeEntity(data[0].field_description);
//console.log(field_description, "ccccc")
const fieldDescription = [
  {
    fieldDescription:field_description
  }];
  const field_description2 =  decodeEntity(data[0].field_description2);

const fieldDescription2 = [
  {
    fieldDescription2:field_description2
  }
];


  return (<Box className='e2e-capablities proactively-engineered-solutions' >
       <Container maxWidth={false} sx={{ maxWidth: '1736px' }}>
        <Grid container>
          <Box className='white-box'></Box>
          <Grid item xs={12} md={6}>
          {/* <img src={e2eImage} alt="" width="100%" /> */}
          <img src={data[0].field_image_url_1!=="" ? data[0].field_image_url_1: e2eImage} alt="" width="100%"/> 
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={4} className='content'>
              <Typography variant="h1" fontWeight="500" color="#4A4948" mb={3} className='section-heading'>
                {(data[0].title!=="" ? data[0].title.split("amp;") : data[0].title)}
              </Typography>
               {  field_description!==''?
               fieldDescription.map((v) => (
                   <Typography component="p" mb="20px" fontSize='25px' maxWidth="672px">
                        <div  dangerouslySetInnerHTML={{ __html: v.fieldDescription }} /> 
                    </Typography>
                )) :'' 
              }  

       
               {field_description2!==""?
               fieldDescription2.map((v) => (
                  <Typography component="p" mb="20px" fontSize='25px' maxWidth="672px">
                      <div dangerouslySetInnerHTML={{ __html: v.fieldDescription2 }} /> 
                    
                  </Typography>
              )):''
                                        
              } 
              {data[0].body!==''?
              <Typography mb="20px" fontSize='25px'>
              <div dangerouslySetInnerHTML={{ __html: data[0].body}}/>
          </Typography> :
          ''
            }
                                 
            </Box>
          </Grid>
        </Grid>
      </Container> 
    </Box>
    )
  }
  //console.log("data", contentData)


export default SectionTextRight1;