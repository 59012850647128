import React,{FC} from 'react'
import { Box,Container,Grid,Typography } from '@mui/material'
import Link from '@mui/material/Link';
import balloon from '../../../assets/images/balloon.jpg';
const SectionRight1=(props: any) => {
    const { data } = props;
    const isBackground = true;
 
    function decodeEntity(inputStr:any) {
        var textarea = document.createElement("textarea");
        //console.log(textarea,"textarea")
        textarea.innerHTML = inputStr;
        return textarea.value;
    }
    const field_description =  decodeEntity(data[0].field_description);
    
    const fieldDescription = [
        {
          fieldDescription:
          field_description
        }
      ];
//console.log(fieldDescription, "ffff");
        return ( 
            <Box className='speed-and-innovation' py={5}>
                <Container maxWidth={false} sx={{ maxWidth: '1600px' }}>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            {/* <img src={balloon} alt="" width="100%" /> */}
                         <img src={data[0].field_image_url_1!=="" ? data[0].field_image_url_1: data[0].field_static_image} alt="" width="100%"/> 
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Box className='content' p={5}>
                            
                                <Typography variant="h1" mb="20px" fontWeight="500" maxWidth="654px" color="#4A4948" className='section-heading'>
                                   {data[0].title.split("amp;")}
                                </Typography>
                                {/* <Typography mb="20px" fontSize='25px' maxWidth="672px">
                                <div  dangerouslySetInnerHTML={{ __html: field_description }} />
                                </Typography> */}
                                  {fieldDescription.map((v) => (
                                    <Typography component="p" mb="20px" fontSize='25px' maxWidth="672px">
                                        <div  dangerouslySetInnerHTML={{ __html: v.fieldDescription }} /> 
                                    </Typography>
                                        ))} 
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
           </Box>
        )
}

export default SectionRight1;
