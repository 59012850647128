import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import { menuDataFromConfig } from '../Config'
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import he from 'he';
import useAnalyticsEventTracker from '../components/useAnalyticsEventTracker';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


function MenuTest(props: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [nestedData, setNestedData] = useState<any>(null);
  const [activeIndex, setActiveIndex] = React.useState('');
  const [activeSubMenuIndex, setActiveSubMenuIndex] = React.useState<any>('');
  const [activeId, setActiveId] = useState<any>('');
  const [open1, setOpen1] = React.useState(false);
  const [isHovered, setIsHovered] = useState(false);

  function handleClick(event: any, items: any, index: any) {

    if (anchorEl !== event.currentTarget) {

      setAnchorEl(event.currentTarget);
      setNestedData(items);
     
    }
    setIsHovered(true);
  }

  function handleClose() {
    setIsHovered(false);
    localStorage.setItem("currentTab", window.location.pathname);
    setAnchorEl(null);
    setOpen1(!open1);

  }
  // const gaEventTracker:any;
  let menuTitle: any;
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const handleOnClick = (event: any, items: any, index: any) => {
    localStorage.setItem("currentTab", window.location.pathname);
    setActiveIndex(index);
  };


  const handleClick1 = (event: any, items: any, i: any) => {
    setOpen1(!open1);
    setActiveSubMenuIndex(i);

  };


  const gaEventTracker = useAnalyticsEventTracker(window.location.pathname);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <List className='main-navs' sx={{ display: 'flex', justifyContent: 'end', p: '0', mt: '36px', mb: '28px' }}>
          {

props.menuData.map((menu: any, index: any) => {
  console.log("menu elements in main menu....", menu);
  console.log("menu items in main menu....", menu.items);
  
  if (menu.title === 'Engineered Components') {
    // Separate items into two arrays based on sorting criteria
    const sortedItemsLeft = menu.items.filter((item: any) => item.title.toUpperCase().charCodeAt(0) >= 'A'.charCodeAt(0) && item.title.toUpperCase().charCodeAt(0) <= 'I'.charCodeAt(0));
    const sortedItemsRight = menu.items.filter((item: any) => item.title.toUpperCase().charCodeAt(0) >= 'J'.charCodeAt(0));
  console.log("sortedItemsLeft",sortedItemsLeft);
  console.log("sortedItemsRight",sortedItemsRight);
    // Concatenate the two sorted arrays
  
    return (
        menu.items && menu.items.length && menu.items.length > 0 ?
    
        <ListItem
        onMouseOver={(e) => handleClick(e, menu.items, index)}
        onClick={(e) => handleOnClick(e, menu.items, index)}
        className={activeIndex === index ? "active" : "unactive"}
  
        sx={{ p: '0', width: 'auto', ml: { xl: '78px', lg: '40px', xs: '20px' } }} >
  
        <Link to={he.decode(menu.to?.replace(/\s+/g, ''))} className="link" href="#" onClick={() => gaEventTracker('check level-1')} >
  
          <ListItemText>{he.decode(menu.title)}</ListItemText>
        </Link>
       


        <div className="dropdown-menu-engineered"> {/* Wrap menu items in a div */}
        {isHovered && (
                <div style={{ position: 'absolute', transform: 'translateY(-60%)', left: '45%',  display: isHovered  ? 'block' : 'none' }}>
                  <ArrowDropUpIcon sx={{ color: '#29a7df', fontSize: '3rem' }} />
                </div>
              )}
            {/* Render menu items for the left div */}
            <div className="left-div">
                {sortedItemsLeft.map((itm: any, index: any) => (
                    <React.Fragment key={index}>
  
                        <ListItem>
                            <Link to={he.decode(itm.to?.replace(/\s+/g, ''))} href="#" onClick={() => gaEventTracker('check level-2')} style={{ fontWeight: 500, fontSize: 17 }}>
                                {he.decode(itm.title)}
                            </Link>
  
                        </ListItem>
                        {itm.items && itm.items.length > 0 &&
                  <List component="div" disablePadding className="bg-submenu" key={activeSubMenuIndex}>
                  {itm.items.map((sub: any, i: any) =>
                      <Link to={sub.to ? he.decode(sub.to.replace(/\s+/g, '')) : ''} className="link" href="#" key={i}>
                          <ListItemButton sx={{ pl: "20px" }}>
                              {he.decode(sub.title)}
                          </ListItemButton>
                      </Link>
                  )}
              </List>
              
                        }
                    </React.Fragment>
                ))}
            </div>
    
            {/* Render menu items for the right div */}
            <div className="right-div">
                {sortedItemsRight.map((itm: any, index: any) => (
                    <React.Fragment key={index}>
                        <ListItem>
                            <Link to={he.decode(itm.to?.replace(/\s+/g, ''))} href="#" onClick={() => gaEventTracker('check level-2')} style={{ fontWeight: 500, fontSize: 17 }}>
                                {he.decode(itm.title)}
                            </Link>
                     
                        </ListItem>
                
                        {itm.items && itm.items.length > 0 &&
                            <List component="div" disablePadding className="bg-submenu" key={activeSubMenuIndex}>
                              {itm.items.map((sub: any, i: any) =>
    <Link to={sub.to ? he.decode(sub.to.replace(/\s+/g, '')) : ''} className="link" href="#" key={i}>
        <ListItemButton className="active" sx={{ pl: "20px" }}>
            {he.decode(sub.title)}
        </ListItemButton>
    </Link>
)}

                            </List>
                        }
                    </React.Fragment>
                ))}
            </div>
        </div>
    </ListItem>
                    :
                    <ListItem
                      onClick={(e) => handleOnClick(e, menu.items, index)}
                      className={activeIndex === index ? "active" : "unactive"}
                      sx={{ p: '0', width: 'auto', ml: { xl: '78px', lg: '40px', xs: '20px' } }}>
                      <Link to={he.decode(menu.to?.replace(/\s+/g, ''))} className="link">
                        <ListItemText className="active">{he.decode(menu.title)}</ListItemText>
                      </Link>
                    </ListItem>
                )
              }

else{

console.log("other menu title**********")
return (
  menu.items && menu.items.length && menu.items.length > 0 ?

    <ListItem
      onMouseOver={(e) => handleClick(e, menu.items, index)}
      onClick={(e) => handleOnClick(e, menu.items, index)}
      className={activeIndex === index ? "active" : "unactive"}

      sx={{ p: '0', width: 'auto', ml: { xl: '78px', lg: '40px', xs: '20px' } }} >

      <Link to={he.decode(menu.to?.replace(/\s+/g, ''))} className="link" href="#" onClick={() => gaEventTracker('check level-1')} >

        <ListItemText>{he.decode(menu.title)}</ListItemText>
      </Link>

      <List className="dropdown-menu">
        {(nestedData instanceof Array) && nestedData.map((itm, index) => {
          const items = itm.items

          return (
            <>

              <ListItem>

                <Link to={he.decode(itm.to?.replace(/\s+/g, ''))} href="#" onClick={() => gaEventTracker('check level-2')} >
                  {he.decode(itm.title)}     </Link>



                {itm.items && itm.items.length && itm.items.length > 0 ?


                  <ListItemButton
                    onClick={(e) => handleClick1(e, itm.items, itm.id)}>


                  </ListItemButton>

                  : null}
              </ListItem>
              {
                itm.items && itm.items.length && itm.items.length > 0 ?
                  <Collapse key={activeSubMenuIndex} in={open1} timeout="auto" unmountOnExit>
                    {activeSubMenuIndex === itm.id ?

                      <List component="div" disablePadding className="bg-submenu" key={activeSubMenuIndex}>
                        <ListItemButton sx={{ pl: "20px" }}>

                          <li>
                            {itm.items && itm.items.length && itm.items.length > 0 ?
                              itm.items.map((sub: any, i: any) =>
                                <>

                                  <Link to={itm.items && itm.items.length && itm.items.length > 0 ? he.decode(sub?.to?.replace(/\s+/g, '')) : ''} className="link" href="#" onClick={() => gaEventTracker('check level-1')} >

                                    {he.decode(sub.title)}
                                  </Link>
                                </>
                              ) : null}
                          </li>

                        </ListItemButton>
                      </List>
                      : null}
                  </Collapse>
                  : null
              }
            </>

          )
        })
        }
      </List>
    </ListItem>

    :
    <ListItem
      onClick={(e) => handleOnClick(e, menu.items, index)}
      className={activeIndex === index ? "active" : "unactive"}
      sx={{ p: '0', width: 'auto', ml: { xl: '78px', lg: '40px', xs: '20px' } }}>
      <Link to={he.decode(menu.to?.replace(/\s+/g, ''))} className="link">
        <ListItemText className="active">{he.decode(menu.title)}</ListItemText>
      </Link>
    </ListItem>
)


}
            }
            )
          }
        </List>
      </div >
    </ClickAwayListener >
  );
}

export default MenuTest;