

import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  CONTENT_URL, MICRO_CONTENT_URL, CLEANROOM_MOLDING_PAGE_CONTENT_URL, MEDICAL_POLYMER_URL, METALS_CONTENT_URL,
  ADVANCED_CATHETERS_CONTENT_URL, MANUFACTURING_CONTENT_URL, COMPLEX_EXTRUSION_PAGE_CONTENT_URL, LEADERSHIP_PAGE_CONTENT_URL,
  CAREERS_PAGE_CONTENT_URL, BALLOONS_PAGE_CONTENT_URL, WHO_WE_ARE_PAGE_CONTENT_URL, MARKETS_PAGE_CONTENT_URL,
  QUALITY_PAGE_CONTENT_URL, CERTIFICATIONS_PAGE_CONTENT_URL, LOCATIONS_PAGE_CONTENT_URL, IMAGEGUIDEDINTERVENSION_PAGE_CONTENT_URL, COMPONNET_DEVELOPEMENT,
  NEW_PRODUCT_DEVELOPEMNT, TECH_TRANSFER, MICRO_OVERMOLDING_URL, TINTUBE_MANUFACTURING, MDI_COATING, PHARMACEUTICAL_PACKAGING_PAGE_CONTENT_URL
} from '../common/APi/apiurl';
import {
  formatHeadingSection, formatRightSection1, formatCenterSection10, formatCommonRepitativeSectionType,
  formatBalloonCathTableType,
  formatExpertSection
} from '../common/utils/utils';
import { pageResponse } from '../Service/ServiceDetail';
import BannerSection from './sections/ServiceDetailsLevel2/BannerSection';
import SectionRight1 from './sections/ServiceDetailsLevel2/SectionTextRight1';
import SectionCenterText10 from './sections/ServiceDetailsLevel2/SectionCenterText10';
import CommonRepetativeSections from './sections/ServiceDetailsLevel2/CommonRepetativeSections';
import CommonCreativeSolution from '../components/CommonCreativeSolution'
import Header from '../components/HeaderSections/Header';
import ExploreAboutBiomerics from '../components/sections/ServiceDetailsLevel2/ExploreAboutBiomerics';
import TopSubMenuAboutUs from '../components/sections/ServiceDetailsLevel2/TopSubMenuAboutUs'
import CommonTable from './sections/ServiceDetailsLevel2/TabelsSections/CommonTable';
import { MICRO_METAL_FINISHED_ASSEMBLY_URL } from './../common/APi/apiurl';


const Level2 = () => {
  console.log('level 2')
  let pageURL;
  const pathName = useLocation();
  let currentPage = pathName.pathname;
  const location = useLocation().pathname;
  const [loading, setLoading] = useState<boolean>(true)
  const [pageContent, setContent] = useState<null | any>(null);
  const [HeadingSectionData, setHeadingSection] = useState<null | any>(null);
  const [SectionRightData1, setSectionRightData1] = useState<null | any>(null);
  const [centerSectionData10, setCenterSectionData10] = useState<null | any>(null);
  const [repitativeSectionTypeData, setRepitativeSectionTypeData] = useState<null | any>(null);
  const [TableSectionTypeData, setTableSectionTypeData] = useState<null | any>(null);
  const [hash, setHash] = useState<string>(pathName.hash ? pathName.hash : "");
  const [expertSectionData, setExpertSection] = useState<null | any>(null);

  const urlContentMappings = [
    { url: "/CleanroomMolding", contentUrl: CLEANROOM_MOLDING_PAGE_CONTENT_URL },
    { url: "/PerformanceMaterials", contentUrl: MEDICAL_POLYMER_URL },
    { url: "/Metals", contentUrl: METALS_CONTENT_URL },
    { url: "/AdvancedCatheters&Steerables", contentUrl: ADVANCED_CATHETERS_CONTENT_URL },
    { url: "/Balloons&BalloonCatheters", contentUrl: BALLOONS_PAGE_CONTENT_URL },
    { url: "/ComplexExtrusions", contentUrl: COMPLEX_EXTRUSION_PAGE_CONTENT_URL },
    { url: "/Careers", contentUrl: CAREERS_PAGE_CONTENT_URL },
    { url: "/ManufacturingLineTransfers", contentUrl: MANUFACTURING_CONTENT_URL },
    { url: "/WhoWeAre", contentUrl: WHO_WE_ARE_PAGE_CONTENT_URL },
    { url: "/Markets", contentUrl: MARKETS_PAGE_CONTENT_URL },
    { url: "/Quality", contentUrl: QUALITY_PAGE_CONTENT_URL },
    { url: "/Certifications", contentUrl: CERTIFICATIONS_PAGE_CONTENT_URL },
    { url: "/Locations", contentUrl: LOCATIONS_PAGE_CONTENT_URL },
    { url: "/ImageGuidedIntervention", contentUrl: IMAGEGUIDEDINTERVENSION_PAGE_CONTENT_URL },
    { url: "/ComponentDevelopment", contentUrl: COMPONNET_DEVELOPEMENT },
    { url: "/NewProductDevelopment", contentUrl: NEW_PRODUCT_DEVELOPEMNT },
    { url: "/TechTransfer", contentUrl: TECH_TRANSFER },
    { url: "/Internships", contentUrl: CAREERS_PAGE_CONTENT_URL },
    { url: "/Apprenticeships", contentUrl: CAREERS_PAGE_CONTENT_URL },
    { url: "/Superate", contentUrl: CAREERS_PAGE_CONTENT_URL },
    // { url: "/TintubeManufacturing", contentUrl: TINTUBE_MANUFACTURING},
    // { url: "/MDICoating", contentUrl: MDI_COATING},
  ];

  const mappedURLs = urlContentMappings.map(item => item.url.toLowerCase());


  function compareUrl(currentPage: string, mappedURLs: string[]): boolean[] {
    currentPage = currentPage.toLowerCase(); // Convert currentPage to lowercase

    const matchArray = mappedURLs.map((pageName) => currentPage === pageName);

    return matchArray; // Return the array of booleans
  }

  const matchResults = compareUrl(currentPage, mappedURLs);

  const isSame = matchResults.some((match: any) => match);

  const fetchResponse = async () => {

    if (isSame) {
      const matchedIndex = matchResults.findIndex(match => match);
      const response = await pageResponse(urlContentMappings[matchedIndex].contentUrl);
      setContent(response);
      setLoading(false);
    } else {

      console.error("No matching URL found for currentPage:");
    }
  };

  const formatData = () => {
    let newsData;
    let titlesection: any;
    let HeadingSection;
    let SectionRightData1;
    let centerSectionData10;
    let repitativeSectionTypeData;
    let TableSectionTypeData;
    let expertSection;
    if (pageContent) {
      HeadingSection = formatHeadingSection(pageContent);
      SectionRightData1 = formatRightSection1(pageContent);
      centerSectionData10 = formatCenterSection10(pageContent);
      repitativeSectionTypeData = formatCommonRepitativeSectionType(pageContent);
      TableSectionTypeData = formatBalloonCathTableType(pageContent);
      expertSection = formatExpertSection(pageContent);
      setHeadingSection(HeadingSection);
      setSectionRightData1(SectionRightData1);
      setTableSectionTypeData(TableSectionTypeData);
      setCenterSectionData10(centerSectionData10);
      setRepitativeSectionTypeData(repitativeSectionTypeData);
      setExpertSection(expertSection);
    }
  }

  setTimeout(() => {
    window.addEventListener("scroll", () => {
      document.querySelector('.headerPadding')?.classList.remove('headerPadding');
    })
  }, 1500)

  useEffect(() => {
    const document: Document = window.document || {} as Document;
    if (pathName.hash) {
      if (document?.querySelector(pathName.hash)) {
        setTimeout((e: any) => {
          document?.querySelector(pathName.hash)?.classList.add('headerPadding')
          document?.querySelector(pathName.hash)?.scrollIntoView({
            block: "nearest",
            inline: "start"
          });
        }, 700)

      }

    }
    return () => {
    }
  }, [pathName, loading]);

  useEffect(() => {
    fetchResponse();
  }, [location])

  useEffect(() => {
      window.scrollTo(0, 0)
    }, [location])

  useEffect(() => {
    formatData();

  }, [pageContent])

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("scroll", () => {
        document.querySelector('.headerPadding')?.classList.remove('headerPadding');
      })
    }, 3000)
    return () => {
      window.removeEventListener("scroll", () => {
      })
    }
  }, [])

  useEffect(() => {
    const handleHashScroll = () => {
      if (pathName.hash) {
        const targetElement = document.querySelector(pathName.hash);
        if (targetElement) {
          targetElement.classList.add('headerPadding');
          targetElement.scrollIntoView({ block: 'start', inline: 'start' });
        }
      }
    };

    // Scroll to the specified section when the component mounts
    handleHashScroll();

    // Add a scroll event listener
    const handleScroll = () => {
      const headerPaddingElement = document.querySelector('.headerPadding');
      if (headerPaddingElement) {
        headerPaddingElement.classList.remove('headerPadding');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const headerPaddingElement = document.querySelector('.headerPadding');
      if (headerPaddingElement) {
        headerPaddingElement.classList.remove('headerPadding');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleHashScroll = () => {
      if (pathName.hash) {
        const targetElement = document.querySelector(pathName.hash);
        if (targetElement) {
          targetElement.classList.add('headerPadding');
          targetElement.scrollIntoView({ block: 'start', inline: 'start' });
        }
      }
    };

    handleHashScroll();

    window.addEventListener('hashchange', handleHashScroll);

    return () => {
      window.removeEventListener('hashchange', handleHashScroll);
    };
  }, [pathName]);



  useEffect(() => {
    const lowercasePathname = pathName.pathname.toLowerCase();
  
    const lowercaseHash = pathName.hash.toLowerCase();
  
    if (lowercaseHash) {
      setTimeout(() => {
        const anchor = document.createElement('a');
        anchor.href = lowercaseHash;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }, 1000);
    }
  }, [pathName]);


  return (
    <>
      {loading ? <h1 style={{ display: 'none' }}>Loading....</h1> :
        <React.Fragment>
          {currentPage === "/Locations" ? <TopSubMenuAboutUs currentPage={"/Locations"} />
            : currentPage === "/WhoWeAre" ? <TopSubMenuAboutUs currentPage={"/WhoWeAre"} /> :
              currentPage === "/Quality" ? <TopSubMenuAboutUs currentPage={"/Quality"} /> :
                // currentPage==="/Careers"?<TopSubMenuAboutUs  currentPage={"/Careers"}/>:
                ''
          }

          <BannerSection data={HeadingSectionData} />
          <SectionRight1 data={SectionRightData1} />
          {
          <CommonRepetativeSections data={repitativeSectionTypeData} banner={expertSectionData} />
          }

          {TableSectionTypeData !== '' ?
            <CommonTable data={TableSectionTypeData} /> : ''
          }

          {centerSectionData10 == "" ? <ExploreAboutBiomerics /> :
            <SectionCenterText10 data={centerSectionData10} />
          }
          <CommonCreativeSolution />

        </React.Fragment>
      }
    </>
  )
}

export default Level2
